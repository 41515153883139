import React, { useEffect } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import AddressHistory from '../address/address-history';

import { ProposalStepProps } from '../types/ProposalStepProps';
import { logLabelEvent } from '../../../helpers/analyticsHelper';
import { errorMessagesToString } from '../../../helpers/validation';
import { GetProposalTypeLabel, GetProposalStepLabel } from '../../../helpers/enumsHelper';

//TODO: why do we need onKeyDown here? Do we need the same for all other form steps?
const PersonalStepFour: React.FC<ProposalStepProps> = ({ id, stepNumber, formData, onBack, onForward }) => {
    const methods = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined
    });
    const { handleSubmit, formState, formState: { errors } } = methods;

    const submitCallback = (data) => {
        if (onForward) {
            onForward(stepNumber, data);
            logLabelEvent(('prop_form_tracking_' + GetProposalTypeLabel(id)), 'Click', GetProposalStepLabel(id, stepNumber));
        }
    }

    const handleBackClick = () => {
        if (onBack) {
            onBack(stepNumber);
        }
    }

    useEffect(() => {
        if (formState.isSubmitted && !formState.isValid) {
            if(Object.keys(errors).length) {
                logLabelEvent(('prop_form_tracking_' + GetProposalTypeLabel(id)), ('step-' + stepNumber), errorMessagesToString(errors));
            }  
        }
    }, [formState.submitCount]);

    return (
        <>
            <FormProvider {...methods} >
                <form id={id + '-' + stepNumber} onSubmit={handleSubmit(submitCallback)} onKeyDown={(evt) => { if (evt.key === 'Enter' || evt.keyCode === 13) { evt.preventDefault(); evt.stopPropagation(); return false; } }}>
                    <div className="row">
                        <div className="col-24">
                            <AddressHistory formData={formData} minHistoryMonths={36} parentContainer={null} />
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-wrap justify-content-between align-items-center mt-5">
                        <button type="button" className="btn btn-outline-secondary btn-block-xs btn-lg bg-gradient me-md-3 my-3 my-md-0" onClick={handleBackClick}>Previous Step</button>
                        <button type="submit" className="btn btn-primary btn-block-xs btn-lg bg-gradient">Next Step</button>
                    </div>
                </form>
            </FormProvider>
        </>
    );
}

export default PersonalStepFour;