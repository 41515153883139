import React from 'react';
import { useFormContext } from "react-hook-form";
import DateOfBirth from '../common/date-of-birth';
import GenderSelector from '../common/gender-selector';
import MaritalStatusSelector from '../common/marital-status-selector';
import NationalitySelector from '../common/nationality-selector';
import ErrorMessage from '../../layout/forms/error-message';

interface IProps {
    formData: any;
}

//TODO: Join with BusinessPerson
//TODO: clear dependent values\unregister fields on watchPropertyStatus change?
const PersonalDetails: React.FC<IProps> = ({ formData }) => {
    const { register, watch, formState: { errors } } = useFormContext();
    const watchGenderSelector = watch("gender", formData.gender);

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-3 mb-md-4 mb-lg-5">Personal Details</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 col-lg-8 mb-3">
                    <DateOfBirth value={formData.birthDate} collectionName={null} index={null} verifyAge={true} />
                </div>
                <div className="col-24 col-md-12 col-lg-8 mb-3">
                    <GenderSelector defaultValue={formData.gender} collectionName={null} index={null} />
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 col-lg-8 mb-3 mb-md-4">
                    <NationalitySelector defaultValue={formData.nationality} collectionName={null} index={null} />
                </div>
                <div className="col-24 col-md-12 col-lg-8 mb-3 mb-md-4">
                    <MaritalStatusSelector defaultValue={formData.maritalStatus} gender={watchGenderSelector} collectionName={null} index={null} />
                </div>
                <div className="col-24 col-md-12 col-lg-8 mb-3 mb-md-4">
                    <div className="w-100 d-flex flex-wrap">
                        <label htmlFor="dependents" className="w-100 d-flex justify-content-start align-items-center mb-2">Number of dependents <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                        <input style={{width: 60}} type="number" inputMode="numeric" pattern="[0-9]*" aria-describedby="dependents" placeholder="0" min={0} max={99} id="dependents" defaultValue={formData.dependents}
                            {...register("dependents", { required: "Enter a number", min: 0, max: 99, pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.dependents ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage errors={errors} name="dependents" />
                        <p className="w-100 d-block form-text mt-1 mb-0">If you don't have any, just enter 0.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PersonalDetails;