import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { getFieldName, getFieldError, getFieldId } from '../../../helpers/fieldArrayHelper';

interface IProps {
    defaultValue: any;
    collectionName: string;
    index: number;
}

const PropertyFurnitureSelector: React.FC<IProps> = ({ defaultValue, collectionName, index }) => {
    const { register, formState: { errors } } = useFormContext();

    const fieldName = getFieldName("furnishedType", collectionName, index);
    const fieldError = getFieldError("furnishedType", collectionName, index, errors);

    const fieldId = (name) => {
        return getFieldId(name, collectionName, index);
    }

    return (
       <div className="w-100 d-flex flex-wrap">
            <label htmlFor="furnishedType" className="w-100 d-flex justify-content-start align-items-center mb-2">Furnished type <small className="d-inline-block text-danger ms-1">(Required)</small></label>         
            <div className="form-check form-check-inline mt-1 mb-2">
                <input type="radio" value={2} id={fieldId("furnished")} defaultChecked={defaultValue == 1}
                    {...register(fieldName, { required: "Choose a property tenure" })}
                    className={`form-check-input ${fieldError ? "is-invalid" : ""}`}
                />
                <label className="form-check-label d-block pe-4" htmlFor={fieldId("furnished")}>Furnished</label>
            </div>
            <div className="form-check form-check-inline mt-1 mb-2">
                <input type="radio" value={1} id={fieldId("unfurnished")} defaultChecked={defaultValue == 2}
                    {...register(fieldName, { required: "Choose a property tenure" })}
                    className={`form-check-input ${fieldError ? "is-invalid" : ""}`}
                />
                <label className="form-check-label d-block pe-4" htmlFor={fieldId("unfurnished")}>Unfurnished</label>
            </div>
            <ErrorMessage errors={errors} name={fieldName} />
        </div>
    );
}

export default PropertyFurnitureSelector;