import { differenceInMonths, endOfToday, isBefore, startOfDay } from "date-fns";
import { ProposalTypes } from "../../../enums/ProposalTypes";
import { getDate } from "../../../helpers/dateTimeHelper";
import { getNumberOrNull, joinNotEmpty } from "../../../helpers/stringHelper";
import { IsValidDate } from "../../../helpers/validation";

export class ProposalDataHelper {

    public static getProposalData = (proposalType: ProposalTypes, data: any) => {
        switch (proposalType) {
            case ProposalTypes.Personal:
                return ProposalDataHelper.getPersonalProposalData(data);
            case ProposalTypes.BusinessSole:
                return ProposalDataHelper.getSoleProposalData(data);
            case ProposalTypes.BusinessPartner:
                return ProposalDataHelper.getPartnershipProposalData(data);
            case ProposalTypes.BusinessLtd:
                return ProposalDataHelper.getLimitedProposalData(data);
        }
    }

    static getPersonalProposalData = (data) => {
        const result = {};

        result["person"] = {
            hirerTitleId: data["2"]?.title,
            foreName: data["2"]?.firstName,
            middleName: data["2"]?.middleName,
            surName: data["2"]?.lastName,

            mobile: data["2"]?.mobileNumber,
            landline: data["2"]?.homeNumber,
            email: data["2"]?.emailAddress,

            birthDate: data["3"].birthDate,
            genderId: data["3"]?.gender,
            nationalityId: data["3"]?.nationality,
            maritalStatusId: data["3"]?.maritalStatus,
            dependents: data["3"]?.dependents,

            addresses: data["4"]?.addresses?.filter(item => item !== null).map((item, index) => {
                return ProposalDataHelper.getAddress(item, index);
            })
        };

        result["income"] = {
            netMonthlySalary: data["5"]?.netMonthlySalary,
            grossMonthlySalary: data["5"]?.grossMonthlySalary,
            partnerNetMonthlySalary: data["5"]?.partnerNetMonthlySalary,
            partnerGrossMonthlySalary: data["5"]?.partnerGrossMonthlySalary,
            otherIncome: data["5"]?.hasOtherIncomes === 'yes' ? data["5"]?.otherIncome : null,
            otherIncomeType: data["5"]?.otherIncomeType || null,
            otherIncomeDetails: data["5"]?.hasOtherIncomes === 'yes' ? data["5"]?.otherIncomeDetails : null,

            mortgageRent: data["6"]?.mortgageRent,
            utilityBills: data["6"]?.utilityBills,
            loans: data["6"]?.loans,
            otherExpenses: data["6"]?.otherExpenses,
            currentCarPayment: data["6"]?.currentCarPayment,
            replacesCurrentCar: data["6"]?.replacesCurrentCar === "yes"
        };

        if (data["6"]) {
            result["creditCards"] = {
                amex: data["6"].amex || false,
                masterCard: data["6"].masterCard || false,
                visa: data["6"].visa || false,
                other: data["6"].other || false,
                none: data["6"].none || false,
                visaDebit: data["6"].visaDebit || false,
                masterDebitCard: data["6"].masterDebitCard || false
            };
        }

        if (data["7"] && data["7"].employments) {
            result["employments"] = data["7"].employments.filter(item => item !== null).map((item, index) => {
                return ProposalDataHelper.getEmployment(item, index);
            });
        }

        //TODO: follow this logic to exclude not existing details for all prop types?
        if (data["8"]) {
            result["bank"] = ProposalDataHelper.getBankDetails(data["8"])
        }

        if (data["9"]) {
            result["vulnerability"] = ProposalDataHelper.getVulnerabilityDetails(data["9"])
        }

        result["notes"] = data["6"]?.notes;

        return result;
    }

    static getSoleProposalData = (data) => {
        const result = ProposalDataHelper.getBusinessProposalBase(data["2"], data["3"]);
        result["mobile"] = data["3"]?.mobileNumber;
        result["businessPerson"] = ProposalDataHelper.getBusinessPerson(data["4"], 0);
        result["bank"] = ProposalDataHelper.getBankDetails(data["5"]);

        return result;
    }

    static getPartnershipProposalData = (data) => {
        const result = ProposalDataHelper.getBusinessProposalBase(data["2"], data["3"]);
        result["partners"] = [
            ProposalDataHelper.getBusinessPerson(data["4"], 0),
            ProposalDataHelper.getBusinessPerson(data["5"], 1)
        ];
        result["bank"] = ProposalDataHelper.getBankDetails(data["6"]);

        return result;
    }

    static getLimitedProposalData = (data) => {
        const result = ProposalDataHelper.getBusinessProposalBase(data["2"], data["3"]);
        result["parentCompany"] = data["2"]?.parentCompany;
        result["companyReg"] = data["2"]?.companyReg;
        result["directors"] = [
            ProposalDataHelper.getBusinessPerson(data["4"], 0)
        ];

        if (data["5"] && data["5"]?.add === "yes") {
            result["directors"].push(ProposalDataHelper.getBusinessPerson(data["5"], 1));
        }

        result["bank"] = ProposalDataHelper.getBankDetails(data["6"]);

        return result;
    }

    static getAddress = (address, index: number) => {
        const result = {
            ...ProposalDataHelper.getBaseAddress(address, false),
            ...{
                fromDate: address?.fromDate || null,
                monthsAtAddress: address?.timeAtAddressMonths ?? null,
                yearsAtAddress: address?.timeAtAddressYears ?? null,

                index: index
            }
        };

        return result;
    }

    static getBaseAddress = (address, excludeTownAndCounty: boolean) => {
        let result = {
            propertyStatusId: address.propertyStatus || null,
            propertyTypeId: address.propertyType || null,
            furnishedType: address.furnishedType || null,
            propertyTenureId: (address.propertyStatus == "1" || address.propertyStatus == "3") ? address.propertyTenure : null,
            address1: address?.address1,
            address2: address?.address2,
            address3: excludeTownAndCounty ? joinNotEmpty(", ", [address?.address3, address?.town, address?.county]) : address?.address3,
            postcode: address?.postcode
        };

        if (!excludeTownAndCounty) {
            result = {
                ...result,
                ...{
                    town: address?.town,
                    county: address?.county
                }
            };
        }

        return result;
    }

    static getEmployment = (data, index: number) => {
        let result = {
            typeId: data?.type
        };
        
        if (getRetiredState(result.typeId)) {
            result = {
                ...result,
                ...{
                    startDate: data?.fromDate,
                    yearsEmployed: data?.years,
                    monthsEmployed: data?.months,

                    index: index
                }
            };
        } else if (getEmploymentState(result.typeId)) {
            result = {
                ...result,
                ...{
                    position: data?.position,
                    employerName: data?.employerName,
                    natureOfBusiness: data?.natureOfBusiness,
                    telephone: data?.landline,

                    startDate: data?.fromDate,
                    yearsEmployed: data?.years,
                    monthsEmployed: data?.months,

                    address: ProposalDataHelper.getBaseAddress(data, true),

                    index: index
                }
            };
        }

        return result;
    }

    static getBusinessPerson = (data, index) => {
        return {
            hirerTitleId: data?.title,
            foreName: data?.firstName,
            middleName: data?.middleName,
            surName: data?.lastName,
            name: joinNotEmpty(" ", [data?.firstName, data?.middleName, data?.lastName]),

            birthDate: data?.birthDate,
            genderId: data?.gender,
            nationalityId: data?.nationality,
            maritalStatusId: data?.maritalStatus,

            addresses: data?.addresses?.filter(item => item !== null).map((item, index) => {
                return ProposalDataHelper.getAddress(item, index);
            }),

            index: index
        };
    }

    static getBankDetails = (data) => {
        return {
            sortCode: data?.sortCode,
            bankName: data?.bankName,
            accountName: data?.accountName,
            accountNumber: data?.accountNumber,

            address: ProposalDataHelper.getBaseAddress(data, true),
            yearsWithBank: getNumberOrNull(data?.bankYears)
        };
    }

    static getVulnerabilityDetails = (data) => {
        return {
            VulnerabilityQ1a: (data?.hasHealthIssues ? (data?.hasHealthIssues === "yes" ? true : false) : null),
            VulnerabilityQ1b: (data?.hasApplicationVulnerability ? (data?.hasApplicationVulnerability === "yes" ? true : false) : null),
            VulnerabilityQ1c: (data?.hasPaymentVulnerability ? (data?.hasPaymentVulnerability === "yes" ? true : false) : null),
            VulnerabilityQ2: (data?.hasLifeEvent ? (data?.hasLifeEvent === "yes" ? true : false) : null),
            VulnerabilityQ3: (data?.hasCircumstances ? (data?.hasCircumstances === "yes" ? true : false) : null),
            VulnerabilityQ4: (data?.hasFutureCircumstances ? (data?.hasFutureCircumstances === "yes" ? true : false) : null),
            VulnerabilityQ5a: (data?.hasContractAgreed ? (data?.hasContractAgreed === "yes" ? true : false) : null),
            VulnerabilityQ5b: (data?.needsHelp ? (data?.needsHelp === "yes" ? true : false) : null),
        };
    }

    static getBusinessProposalBase = (businessDetails, contactDetails) => {
        return {
            tradingName: businessDetails?.tradingName,
            natureOfBusiness: businessDetails?.natureOfBusiness,
            totalFleetSize: businessDetails?.totalFleetSize,
            dateEstablished: businessDetails?.dateEstablished,
            vatNumber: businessDetails?.isVatRegistered === 'yes' ? businessDetails?.vatNumber : null,

            email: contactDetails?.emailAddress,
            landline: contactDetails?.landline,
            addresses: [ProposalDataHelper.getBaseAddress(contactDetails, false)],
            notes: contactDetails?.notes
        };
    }
}

export const getTotalAddressesHistory = (items: any[]): number => {
    let totalHistory = 0;

    if (items?.length) {
        items.forEach(item => {
            totalHistory += (item.totalMonthsAtAddress ?? 0);
        });
    }

    return totalHistory;
}

export const getTotalEmploymentHistory = (items: any[]): number => {
    let totalHistory = 0;

    if (items?.length) {
        items.forEach(item => {
            totalHistory += (item.totalMonths ?? 0);
        });
    }

    return totalHistory;
}

export const getHistoryItemPeriodDetails = (fromDate: string, toDate: string) => {
    let years: number = null;
    let months: number = null;
    let totalMonths: number = null;

    if (IsValidDate(fromDate) && (!toDate || IsValidDate(toDate))) {
        const laterDateObj = toDate ? startOfDay(getDate(toDate)) : endOfToday();
        const earlierDateObj = startOfDay(getDate(fromDate));

        if (isBefore(earlierDateObj, laterDateObj)) {
            totalMonths = differenceInMonths(laterDateObj, earlierDateObj);
            years = Math.floor(totalMonths / 12);
            months = totalMonths % 12;
        }
    }

    return {
        years: years,
        months: months,
        totalMonths: totalMonths
    };
}

export const getEmploymentState = (typeId: string): boolean => {
    let result = false;

    if (typeId) {
        switch (typeId) {
            case "6":
            case "7":
                result = false;
                break;

            default:
                result = true;
                break;
        }
    }

    return result;
}

export const getRetiredState = (typeId: string): boolean => {
    let result = false;

    if (typeId) {
        switch (typeId) {
            case "4":
                result = true;
                break;

            default:
                result = false;
                break;
        }
    }

    return result;
}