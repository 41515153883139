import React, { useEffect, useState } from 'react';
import { useFormContext } from "react-hook-form";
import AddressInput from '../address/address-input';
import LandlineNumber from '../common/landline-number';
import DateInput from '../common/date';
import ErrorMessage from '../../layout/forms/error-message';

import { getFieldName, getFieldId, getFieldError } from '../../../helpers/fieldArrayHelper';
import { getEmploymentState, getRetiredState, getHistoryItemPeriodDetails } from '../helpers/ProposalDataHelper';
import { IsValidDate } from '../../../helpers/validation';

interface IProps {
    title: string;
    employmentData: any;

    collectionName: string | null;
    index: number;

    //events
    onTimePeriodUpdate: any | null;
}

const EmploymentHistoryItem: React.FC<IProps> = ({ title, employmentData, collectionName, index, onTimePeriodUpdate }) => {
    const { setValue, register, clearErrors, formState: { errors } } = useFormContext();

    const [isEmployed, setIsEmployed] = useState<boolean>(getEmploymentState(employmentData.type ?? null));
    const [isRetired, setIsRetired] = useState<boolean>(getRetiredState(employmentData.type ?? null));
    const [fromDate, setFromDate] = useState<string>(employmentData.fromDate ?? null);
    const [toDate, setToDate] = useState<string>(employmentData.toDate ?? null);
    const dateError = getFieldError("fromDate", collectionName, index, errors);

    useEffect(() => {
        setFromDate(employmentData.fromDate ?? null);
        setToDate(employmentData.toDate ?? null);
    }, [employmentData]);

    useEffect(() => {
        if (!isEmployed) {
            setValue(fieldName("fromDate"), null);
            setFromDate(null);
        }
    }, [isEmployed]);

    useEffect(() => {
        const periodDetails = getHistoryItemPeriodDetails(fromDate, toDate);

        setValue(fieldName("years"), periodDetails.years || 0);
        setValue(fieldName("months"), periodDetails.months || 0);
        setValue(fieldName("totalMonths"), periodDetails.totalMonths || 0);

        if (onTimePeriodUpdate) {
            onTimePeriodUpdate(fromDate, periodDetails.totalMonths, index);
        }
    }, [fromDate, toDate]);

    const fieldName = (name: string): string => {
        return getFieldName(name, collectionName, index);
    }

    const fieldId = (name: string): string => {
        return getFieldId(name, collectionName, index);
    }

    const fieldError = (name: string) => {
        return getFieldError(name, collectionName, index, errors);
    }

    const onDateChange = (date: Date) => {
        let formattedDate = date.toLocaleDateString("en-GB");
        
        setValue(fieldName("fromDate"), formattedDate);
        setFromDate(formattedDate);

        clearErrors(fieldName("fromDate"));
    }

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h3 className="w-100 d-block h4 mt-3 mt-md-4 mt-lg-5 mb-3">{title}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-8 mb-3">
                    <label htmlFor={fieldId("type")} className="w-100 d-flex justify-content-start align-items-center mb-2">Type of Employment <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <select id={fieldId("type")} defaultValue={employmentData.type}
                        {...register(fieldName("type"), { required: "Select an employment type." })}
                        className={`form-select ${fieldError("type") ? "is-invalid" : ""}`}
                        onChange={(evt) => { setIsEmployed(getEmploymentState(evt.target.value)); setIsRetired(getRetiredState(evt.target.value)) }}
                    >
                        <option value="">Please select...</option>
                        <option value="1">Employed Full Time</option>
                        <option value="2">Employed Part Time</option>
                        <option value="3">Employed Temp</option>
                        <option value="4">Retired</option>
                        <option value="5">Home Maker</option>
                        <option value="6">Student</option>
                        <option value="7">Unemployed</option>
                        <option value="8">Self-employed Full Time</option>
                        <option value="9">Self-employed Part Time</option>
                    </select>
                    <ErrorMessage errors={errors} name={fieldName("type")} />
                </div>
                {isEmployed && !isRetired &&
                    <>
                        <div className="col-24 col-md-8 mb-3">
                            <label htmlFor={fieldId("employerName")} className="w-100 d-flex justify-content-start align-items-center mb-2">Employer Name <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <input type="text" aria-describedby={fieldId("employerName")} placeholder="Employer Name" maxLength={100} id={fieldId("employerName")} defaultValue={employmentData.employerName}
                                {...register(fieldName("employerName"), { required: "Enter an employers name", maxLength: 100 })}
                                className={`form-control ${fieldError("employerName") ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage errors={errors} name={fieldName("employerName")} />
                        </div>
                        <div className="col-24 col-md-8 mb-3">
                            <label htmlFor={fieldId("natureOfBusiness")} className="w-100 d-flex justify-content-start align-items-center mb-2">Nature of Business <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <input type="text" aria-describedby={fieldId("natureOfBusiness")} placeholder="Nature of Business" maxLength={100} id={fieldId("natureOfBusiness")} defaultValue={employmentData.natureOfBusiness}
                                {...register(fieldName("natureOfBusiness"), { required: "Enter a nature of business", maxLength: 100 })}
                                className={`form-control ${fieldError("natureOfBusiness") ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage errors={errors} name={fieldName("natureOfBusiness")} />
                        </div>
                    </>
                }
            </div>
            {isEmployed &&
                <>
                    {!isRetired &&
                        <>
                            <div className="row">
                                <div className="col-24 col-md-8 mb-3 mb-md-5">
                                    <label htmlFor={fieldId("position")} className="w-100 d-flex justify-content-start align-items-center mb-2">Position <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                                    <input type="text" aria-describedby={fieldId("position")} placeholder="Job Title" maxLength={100} id={fieldId("position")} defaultValue={employmentData.position}
                                        {...register(fieldName("position"), { required: "Enter a job title.", maxLength: 100 })}
                                        className={`form-control ${fieldError("position") ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage errors={errors} name={fieldName("position")} />
                                </div>
                                <div className="col-24 col-md-8 mb-5">
                                    <LandlineNumber label="Employers Telephone" defaultValue={employmentData.landline} collectionName={collectionName} index={index} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-24">
                                    <h4 className="w-100 d-block h5 mb-3">Employment Address</h4>
                                </div>
                            </div>
                            <AddressInput collectionName={collectionName} index={index} formData={employmentData} />
                        </>
                    }
                    <div className="row">
                        <div className="col-24">
                            <h4 className="w-100 d-block h5 mt-5 mb-3">{isRetired ? "Retirement Date" : "Employment Date"}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-24 col-md-12 col-lg-8 mb-3 mb-md-0">
                            <label htmlFor="startDate" className="w-100 d-flex justify-content-start align-items-center mb-2">{isRetired ? "Retirement " : ""}Start Date <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <div className={`w-100 d-flex flex-nowrap justify-content-start align-items-center ${(fieldError("fromDate") || fieldError("totalMonths")) ? " is-invalid" : ""}`}>
                                <input type="hidden"
                                    {...register(fieldName("fromDate"),
                                        {
                                            required: true,
                                            validate: {
                                                isDate: v => IsValidDate(v)
                                            }
                                        }
                                    )}
                                    defaultValue={fromDate}
                                />
                                <DateInput selectedDate={fromDate} valid={((dateError?.type === 'required' || dateError?.type === 'isDate')) ? false : true} onDateChange={onDateChange} />
                            </div>
                            <ErrorMessage errors={errors} name={fieldName("fromDate")} message="Valid date is required" />
                            <ErrorMessage errors={errors} name={fieldName("totalMonths")} />
                        </div>
                        <div className="col-24 col-md-12 mb-3 mb-md-0 d-none">
                            <label className="w-100 d-flex justify-content-start align-items-center mb-2">Years &amp; months Employed <small className="d-inline-block ms-1"></small>(Auto-generated)</label>
                            <div className="row">
                                <div className="col-12 pe-md-2">
                                    <input type="number" inputMode="numeric" pattern="[0-9]*" disabled placeholder="Years" maxLength={2}
                                        {...register(fieldName("years"), { required: true, pattern: /^[0-9]*$/, min: 0, max: 50 })}
                                        className={`form-control ${fieldError("years") ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage errors={errors} name={fieldName("years")} message="Valid value is required" />
                                </div>
                                <div className="col-12 ps-md-2">
                                    <input type="number" inputMode="numeric" pattern="[0-9]*" disabled placeholder="Months" maxLength={2}
                                        {...register(fieldName("months"), { required: true, pattern: /^[0-9]*$/, min: 0, max: 11 })}
                                        className={`form-control ${fieldError("months") ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage errors={errors} name={fieldName("months")} message="Valid value is required" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-24">
                            <input type="hidden" {...register(fieldName("totalMonths"), { /*required: true,*/ max: { value: 600, message: "Maximum 50 years allowed" } })} />
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default EmploymentHistoryItem;