import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import MainLayout from '../../layout/main-layout';

import { joinNotEmpty } from '../../../helpers/stringHelper';
import { initGA, logPageView } from "../../../helpers/analyticsHelper";
import { GetFullUrl } from 'src/helpers/urlHelper';
import { ConfigContext } from '../../App';

type Props = {
    isGaTracking: boolean;
    isInternal: boolean;
}

const ProposalBusinessPage: React.FC<Props> = ({ isGaTracking, isInternal }) => {
    const config = useContext(ConfigContext);
    const phoneNumber = isInternal ? "0161 703 8744" : "0161 826 9716";

    const [proposalSoleUrl, setProposalSoleUrl] = useState<string>();
    const [proposalPartnerUrl, setProposalPartnerUrl] = useState<string>();
    const [proposalLtdUrl, setProposalLtdUrl] = useState<string>();

    useEffect(() => {
        initGA();
        // @ts-ignore
        window.GA_INITIALIZED = true;
        logPageView();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        let quoteid = queryParams.get("quoteid");
        let enquiryid = queryParams.get("enquiryid");
        let uid = queryParams.get("uid");
        let directfinance = queryParams.get("directfinance");
        let tenantId = queryParams.get("tenantId");
        let origin = queryParams.get("origin");
        let email = queryParams.get("email");

        let urlParams = [];

        if (quoteid) {
            urlParams.push(`quoteid=${quoteid}`);
        }

        if (enquiryid) {
            urlParams.push(`enquiryid=${enquiryid}`);
        }

        if (uid) {
            urlParams.push(`uid=${uid}`);
        }

        if (directfinance) {
            urlParams.push(`directfinance=${directfinance}`);
        }

        if (tenantId) {
            urlParams.push(`tenantId=${tenantId}`);
        }

        if (origin) {
            urlParams.push(`origin=${origin}`);
        }

        if (email) {
            urlParams.push(`email=${email}`);
        }

        setProposalSoleUrl("/proposal_business_sole" + ((urlParams.length > 0) ? `?${joinNotEmpty('&', urlParams)}` : ''));
        setProposalPartnerUrl("/proposal_business_partner" + ((urlParams.length > 0) ? `?${joinNotEmpty('&', urlParams)}` : ''));
        setProposalLtdUrl("/proposal_business_ltd" + ((urlParams.length > 0) ? `?${joinNotEmpty('&', urlParams)}` : ''));
    }, []);

    useEffect(() => {
        // @ts-ignore
        if (isGaTracking && !window.GA_INITIALIZED) {
            initGA();
            // @ts-ignore
            window.GA_INITIALIZED = true;
        }
    }, [isGaTracking]);

    useEffect(() => {
        if (window && window.dataLayer) {
            window.dataLayer.push({
                'event': 'pageview',
                'pageTitle': window.document.title || '',
                'url': GetFullUrl(),
                'pageCategory': 'businessProp',
            });
        }
    }, []);

    return (
        <MainLayout>
            <Helmet>
                <title>Proposal Business Application</title>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
                <meta name="robots" content="noindex,nofollow" />
                <link rel="apple-touch-icon" sizes="180x180" href={`${config.theme.images.faviconPath}/apple-touch-icon.png`} />
                <link rel="icon" type="image/png" sizes="32x32" href={`${config.theme.images.faviconPath}/favicon-32x32.png`} />
                <link rel="icon" type="image/png" sizes="16x16" href={`${config.theme.images.faviconPath}/favicon-16x16.png`} />
                <link rel="manifest" href={`${config.theme.images.faviconPath}/site.webmanifest`} />
                <link rel="mask-icon" href={`${config.theme.images.faviconPath}/safari-pinned-tab.svg`} color="#b20202" />
                <link rel="shortcut icon" href={`${config.theme.images.faviconPath}/favicon.ico`} />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
                <meta name="theme-color" content="#ffffff"></meta>
                <script id="tag-manager" type="application/javascript">
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PG5CHN');`}
                </script>
            </Helmet>

            <section className="container-fluid py-3 py-md-5 bg-light-grey">
                <div className="container py-3 py-md-5">
                    <div className="w-100 bg-white shadow border-light rounded-3 p-3 p-md-5">
                        <h2 className="mb-3">Secure Business Lease Credit Application</h2>
                        <p className="mb-5">Select the type of application you would like to complete.</p>
                        <div className="row">
                            <div className="col-24 col-md-8 mb-3 mb-md-0">
                                <Link to={`${proposalSoleUrl}`} className="btn btn-secondary btn-block btn-lg px-3 py-4 p-md-4 bg-gradient">Sole Trader Application</Link>
                            </div>
                            <div className="col-24 col-md-8 mb-3 mb-md-0">
                                <Link to={`${proposalPartnerUrl}`} className="btn btn-secondary btn-block btn-lg px-3 py-4 p-md-4bg-gradient">Partnership Application</Link>
                            </div>
                            <div className="col-24 col-md-8 mb-3 mb-md-5">
                                <Link to={`${proposalLtdUrl}`} className="btn btn-secondary btn-block btn-lg px-3 py-4 p-md-4bg-gradient">Limited Company Application</Link>
                            </div>
                        </div>
                        <p className="m-0">If you have any questions or require further information, call our friendly team on <a aria-label="Phone number" href={`tel:${phoneNumber}`}><span className="phonenumber_call_now">{phoneNumber}</span></a> and we&apos;ll be happy to assist you.</p>
                    </div>
                </div>
            </section>
        </MainLayout>
    );
}

export const mapStateToProps = (state) => {
    return {
        isGaTracking: state.isGaTracking,
        isInternal: state.isInternal,
    };
}

export default connect(mapStateToProps)(ProposalBusinessPage);