import React from 'react';
import { useFormContext } from "react-hook-form";
import Notes from '../common/notes';
import ErrorMessage from '../../layout/forms/error-message';
interface IProps {
    formData: any;
}

//TODO: replacesCurrentCar - do we need validation there like for other radio buttons?
const ExpenditureDetails: React.FC<IProps> = ({ formData }) => {
    const { register, watch, formState: { errors } } = useFormContext();
    const watchNoCreditCards = watch("none", formData.none);

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-2">Expenditure Details</h2>
                    <span className="w-100 d-block mb-3 mb-md-4 mb-lg-5 fw-bold text-danger">Please enter your individual expenditure, not the household expenditure.</span>
                    <h3 className="w-100 d-block h4 mb-3">Main Expenditure</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-8 mb-3 mb-md-5">
                    <label htmlFor="mortgageRent" className="w-100 d-flex justify-content-start align-items-center mb-2">Mortgage / Rent <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="input-group">
                        <span className="input-group-text">£</span>
                        <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Amount per month" aria-describedby="mortgageRent" placeholder="Amount per month" id="mortgageRent" defaultValue={formData.mortgageRent}
                            {...register("mortgageRent", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.mortgageRent ? "is-invalid" : ""}`}
                        />
                    </div>
                    <ErrorMessage errors={errors} name="mortgageRent" />
                    <p className="form-text mt-1 mb-0">Average amount per month.</p>
                </div>
                <div className="col-24 col-md-8 mb-3 mb-md-5">
                    <label htmlFor="utilityBills" className="w-100 d-flex justify-content-start align-items-center mb-2">Utility Bills <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="input-group">
                        <span className="input-group-text">£</span>
                        <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Amount per month" aria-describedby="utilityBills" placeholder="Amount per month" id="utilityBills" defaultValue={formData.utilityBills}
                            {...register("utilityBills", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.utilityBills ? "is-invalid" : ""}`}
                        />
                    </div>
                    <ErrorMessage errors={errors} name="utilityBills" />
                    <p className="form-text mt-1 mb-0">Average amount per month.</p>
                </div>
                <div className="col-24 col-md-8 mb-5">
                    <label htmlFor="loans" className="w-100 d-flex justify-content-start align-items-center mb-2">Loans <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="input-group">
                        <span className="input-group-text">£</span>
                        <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Amount per month" aria-describedby="loans" placeholder="Amount per month" id="loans" defaultValue={formData.loans}
                            {...register("loans", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.loans ? "is-invalid" : ""}`}
                        />
                    </div>
                    <ErrorMessage errors={errors} name="loans" />
                    <p className="form-text mt-1 mb-0">Average amount per month.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-24">
                    <h3 className="w-100 d-block h4 mb-3">Credit Card Expenditure</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-24 mb-3">
                    <label className="w-100 d-flex justify-content-start align-items-center mb-2">Which of the following (if any) do you own? <small className="d-inline-block ms-1">(Optional)</small></label>
                    <div className="w-100 d-flex flex-wrap">
                        <div className="form-check form-check-inline">
                            <input type="checkbox" className="form-check-input" aria-label="Visa" id="visaCard" defaultChecked={formData.visa} {...register("visa")} />
                            <label className="form-check-label d-block pe-4" htmlFor="visaCard">Visa</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input type="checkbox" className="form-check-input" aria-label="Mastercard" id="masterCard" defaultChecked={formData.masterCard} {...register("masterCard")} />
                            <label className="form-check-label d-block pe-4" htmlFor="masterCard">Mastercard</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input type="checkbox" className="form-check-input" aria-label="American Express" id="amexCard" defaultChecked={formData.amex}{...register("amex")} />
                            <label className="form-check-label d-block pe-4" htmlFor="amexCard">American Express</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input type="checkbox" className="form-check-input" aria-label="Other" id="otherCard" defaultChecked={formData.other}{...register("other")} />
                            <label className="form-check-label d-block pe-4" htmlFor="otherCard">Other</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input type="checkbox" className="form-check-input" aria-label="None" id="none" defaultChecked={formData.none}{...register("none")} />
                            <label className="form-check-label d-block pe-4" htmlFor="none">None</label>
                        </div>
                    </div>
                    <p className={`w-100 d-block form-text mt-1 ${watchNoCreditCards ? "" : "mb-5"}`}>If you don't have any, just leave them unchecked.</p>
                    {(watchNoCreditCards) && (
                        <>
                            <hr className="w-100 d-block my-3" />
                            <div className="w-100 d-flex flex-wrap">
                                <div className="form-check form-check-inline">
                                    <input type="checkbox" className="form-check-input" aria-label="VisaDebit" id="visaDebitCard" defaultChecked={formData.visaDebit} {...register("visaDebit")} />
                                    <label className="form-check-label d-block pe-4" htmlFor="visaDebitCard">Visa Debit</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input type="checkbox" className="form-check-input" aria-label="MastercardDebit" id="masterDebitCard" defaultChecked={formData.masterDebitCard} {...register("masterDebitCard")} />
                                    <label className="form-check-label d-block pe-4" htmlFor="masterDebitCard">Mastercard Debit</label>
                                </div>
                            </div>
                            <p className="w-100 d-block form-text mt-1 mb-5">If you don't have any, just leave them unchecked.</p>
                        </>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-24">
                    <h3 className="w-100 d-block h4 mb-3">Additional Expenditure</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 col-lg-8 mb-3">
                    <label htmlFor="otherExpenses" className="w-100 d-flex justify-content-start align-items-center mb-2">Other Expenses <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="input-group">
                        <span className="input-group-text">£</span>
                        <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Additional Expenses" aria-describedby="otherExpenses" placeholder="Additional Expenses" id="otherExpenses" defaultValue={formData.otherExpenses}
                            {...register("otherExpenses", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.otherExpenses ? "is-invalid" : ""}`}
                        />
                    </div>
                    <ErrorMessage errors={errors} name="otherExpenses" />
                    <p className="w-100 d-block form-text mt-1 mb-0">Average amount per month. If you don't have any, just enter 0.</p>
                </div>
                <div className="col-24 col-md-12 col-lg-8 mb-3">
                    <label htmlFor="currentCarPayment" className="w-100 d-flex justify-content-start align-items-center mb-2">Current Car Payment <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="input-group">
                        <span className="input-group-text">£</span>
                        <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Car Payment" aria-describedby="currentCarPayment" placeholder="Car Payment" id="currentCarPayment" defaultValue={formData.currentCarPayment}
                            {...register("currentCarPayment", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.currentCarPayment ? "is-invalid" : ""}`}
                        />
                    </div>
                    <ErrorMessage errors={errors} name="currentCarPayment" />
                    <p className="w-100 d-block form-text mt-1 mb-0">Average amount per month. If you don't have one, just enter 0.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-24 mb-3">
                    <label className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-2">Will this replace your current car? <br className="d-block d-md-none" /><small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="yes" id="replaceCar_yes" defaultChecked={formData.replacesCurrentCar === 'yes'} {...register("replacesCurrentCar")} />
                        <label className="form-check-label d-block pe-4" htmlFor="replaceCar_yes">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="no" id="replaceCar_no" defaultChecked={formData.replacesCurrentCar !== 'yes'} {...register("replacesCurrentCar")} />
                        <label className="form-check-label d-block pe-4" htmlFor="replaceCar_no">No</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-24">
                    <Notes defaultValue={formData.notes} />
                </div>
            </div>
        </>
    );
}

export default ExpenditureDetails;