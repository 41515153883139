import React from 'react';
import { useFormContext } from 'react-hook-form';
import DateOfBirth from '../common/date-of-birth';
import FullName from '../common/full-name';
import GenderSelector from '../common/gender-selector';
import MaritalStatusSelector from '../common/marital-status-selector';
import NationalitySelector from '../common/nationality-selector';
import AddressHistory from '../address/address-history';

interface IProps {
    formData: any;
    collectionName?: string;
    index?: number;
}

//TODO: Join with PersonalDetails
//TODO: create common selector component?
//TODO: parentContainer doesn't work properly here so getFieldError can't find matches as property names are incorrect
//TODO: clear dependent values\unregister fields on watchPropertyStatus change?
const BusinessPerson: React.FC<IProps> = ({ formData, collectionName, index }) => {
    const { watch } = useFormContext();
    const watchGenderSelector = watch("gender", formData.gender);

    return (
        <>
            <FullName formData={formData} collectionName={collectionName} index={index} />
            <div className="row">
                <div className="col-24 col-md-8 mb-3">
                    <DateOfBirth value={formData.birthDate} collectionName={collectionName} index={index} verifyAge={true} />
                </div>             
                <div className="col-24 col-md-8 mb-3">
                    <NationalitySelector defaultValue={formData.nationality} collectionName={collectionName} index={index} />
                </div>
                <div className="col-24 col-md-24 col-lg-8 mb-3">
                    <GenderSelector defaultValue={formData.gender} collectionName={collectionName} index={index} />
                </div>  
            </div>
            <div className="row">
                <div className="col-24 col-md-12 col-lg-8 mb-3 mb-md-4">
                    <MaritalStatusSelector defaultValue={formData.maritalStatus} gender={watchGenderSelector} collectionName={collectionName} index={index} />
                </div>
            </div>
            <div className="row">
                <div className="col-24"><hr className="mt-0 mb-3 mb-md-4" /></div>
            </div>
            <AddressHistory formData={formData} minHistoryMonths={36} parentContainer={(collectionName) ? `${collectionName}[${index}]` : null} />
        </>
    );
}

export default BusinessPerson;