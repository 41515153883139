import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

interface IProps {
    formData: any;
    personalData: any;
}

//TODO: update\clear related values when hasOtherIncomes changed
//TODO: input type text or number?
//TODO: add some maxLength for otherIncomeDetails? it joins into notes which is 4000 max
//TODO: NumberNearestIntDown check usage, remove if not needed
const IncomeDetails: React.FC<IProps> = ({ formData, personalData }) => {
    const { register, watch, formState: { errors } } = useFormContext();

    const watchHasOtherIncomes = watch("hasOtherIncomes", formData.hasOtherIncomes);
    let hasPartner = false;

    if (personalData?.maritalStatus) {
        switch (personalData?.maritalStatus) {
            case "2":
            case "7":
                hasPartner = true;
                break;
            default:
                hasPartner = false;
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-3 mb-md-4 mb-lg-5">Income Details</h2>
                    <h3 className="w-100 d-block h4 mb-3">Your Income</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 mb-3">
                    <label htmlFor="grossMonthlySalary" className="w-100 d-flex justify-content-start align-items-center mb-2">Gross Monthly Salary <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="input-group">
                        <span className="input-group-text">£</span>
                        <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Gross Monthly Salary" aria-describedby="grossMonthlySalary" placeholder="Gross Monthly Salary" id="grossMonthlySalary" defaultValue={formData.grossMonthlySalary}
                            {...register("grossMonthlySalary", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.grossMonthlySalary ? "is-invalid" : ""}`}
                        />
                    </div>
                    <ErrorMessage errors={errors} name="grossMonthlySalary" />
                    <p className="form-text mt-1 mb-0">Amount you receive on average per month, before any taxes.</p>
                </div>
                <div className="col-24 col-md-12 mb-5">
                    <label htmlFor="netMonthlySalary" className="w-100 d-flex justify-content-start align-items-center mb-2">Net Monthly Salary <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="input-group">
                        <span className="input-group-text">£</span>
                        <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Net Monthly Salary" aria-describedby="net" placeholder="Net Monthly Salary" id="netMonthlySalary" defaultValue={formData.netMonthlySalary}
                            {...register("netMonthlySalary", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.netMonthlySalary ? "is-invalid" : ""}`}
                        />
                    </div>
                    <ErrorMessage errors={errors} name="netMonthlySalary" />
                    <p className="form-text mt-1 mb-0">Amount you receive on average per month, after any taxes.</p>
                </div>
            </div>
            {hasPartner &&
                <>
                    <div className="row">
                        <div className="col-24">
                            <h3 className="w-100 d-block h4 mb-3">Your Partners Income</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-24 col-md-12 mb-3">
                            <label htmlFor="partnerGrossMonthlySalary" className="w-100 d-flex justify-content-start align-items-center mb-2">Gross Monthly Salary <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <div className="input-group">
                                <span className="input-group-text">£</span>
                                <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Gross Monthly Salary" aria-describedby="partnerGross" placeholder="Gross Monthly Salary" id="partnerGrossMonthlySalary" defaultValue={formData.partnerGrossMonthlySalary}
                                    {...register("partnerGrossMonthlySalary", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                                    className={`form-control ${errors.partnerGrossMonthlySalary ? "is-invalid" : ""}`}
    
                                />
                            </div>
                            <ErrorMessage errors={errors} name="partnerGrossMonthlySalary" />
                            <p className="form-text mt-1 mb-0">If you don't have a partner, just enter 0.</p>
                        </div>
                        <div className="col-24 col-md-12 mb-5">
                            <label htmlFor="partnerNetMonthlySalary" className="w-100 d-flex justify-content-start align-items-center mb-2">Net Monthly Salary <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <div className="input-group">
                                <span className="input-group-text">£</span>
                                <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Net Monthly Salary" aria-describedby="partnerNet" placeholder="Net Monthly Salary" id="partnerNetMonthlySalary" defaultValue={formData.partnerNetMonthlySalary}
                                    {...register("partnerNetMonthlySalary", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                                    className={`form-control ${errors.partnerNetMonthlySalary ? "is-invalid" : ""}`}
    
                                />
                            </div>
                            <ErrorMessage errors={errors} name="partnerNetMonthlySalary" />
                            <p className="form-text mt-1 mb-0">If you don't have a partner, just enter 0.</p>
                        </div>
                    </div>
                </>
            }
            <div className="row">
                <div className="col-24">
                    <h3 className="w-100 d-block h4 mb-3">Additional Income</h3>
                </div>
            </div>
            <div className="row">
                <div className={`col-24 col-md-12 ${watchHasOtherIncomes === "yes" ? "mb-3" : "mb-0"}`}>
                    <label className="w-100 d-block mb-2">Any Other Income?</label>
                    <div className="form-check form-check-inline mt-2 mb-1">
                        <input type="radio" value="no" id="hasOtherIncomes_no" defaultChecked={!formData.hasOtherIncomes || formData.hasOtherIncomes === 'no'}
                            {...register("hasOtherIncomes")} className="form-check-input"
                        />
                        <label className="form-check-label d-block pe-4" htmlFor="hasOtherIncomes_no">No</label>
                    </div>
                    <div className="form-check form-check-inline mt-2 mb-1">
                        <input type="radio" value="yes" id="hasOtherIncomes_yes" defaultChecked={formData.hasOtherIncomes === 'yes'}
                            {...register("hasOtherIncomes")} className="form-check-input"
                        />
                        <label className="form-check-label d-block pe-4" htmlFor="hasOtherIncomes_yes">Yes</label>
                    </div>
                    <p className="w-100 d-block form-text mt-1 mb-0">For example: Any bonuses, investments or property income per month.</p>
                </div>
                {watchHasOtherIncomes === "yes" &&
                    <div className="col-24 col-md-12 mb-3 mb-md-0">
                        <label htmlFor="otherIncome" className="w-100 d-flex justify-content-start align-items-center mb-2">Other Income Amount<small className="d-inline-block text-danger ms-1">(Required)</small></label>
                        <div className="input-group ">
                            <span className="input-group-text">£</span>
                            <input type="number" inputMode="numeric" pattern="[0-9]*" aria-label="Other Income amount" aria-describedby="otherIncome" placeholder="Other Income amount" id="otherIncome" defaultValue={formData.otherIncome}
                                    {...register("otherIncome", { required: "Enter an estimated number", pattern: /^[0-9]*$/ })}
                                    className={`form-control ${errors.otherIncome ? "is-invalid" : ""}`}
    
                                />
                            <div className="input-group-text">
                                <input className={`form-check-input mt-0 ${errors.otherIncomeType ? "is-invalid" : ""}`} type="radio" name="otherIncomeType" {...register("otherIncomeType", { required: "Select either per month or year" })} value={1} defaultChecked={formData.otherIncomeType == 1} />
                                <small className="d-inline-block ms-2">Per month</small>
                            </div>
                            <div className="input-group-text">
                                <input className={`form-check-input mt-0 ${errors.otherIncomeType ? "is-invalid" : ""}`} type="radio" name="otherIncomeType" {...register("otherIncomeType", { required: "Select either per month or year" })} value={2} defaultChecked={formData.otherIncomeType == 2} />
                                <small className="d-inline-block ms-2">Per year</small>
                            </div>
                        </div>
                        <ErrorMessage errors={errors} name="otherIncome" />
                        <ErrorMessage errors={errors} name="otherIncomeType" />
                        <p className="w-100 d-block form-text mt-1 mb-0">Please total them up and enter one figure and select either per month or per year.</p>
                    </div>
                }
            </div>
            {watchHasOtherIncomes === "yes" &&
                <div className="row">
                    <div className="col-24">
                        <label htmlFor="otherIncomeDetails" className="w-100 d-flex justify-content-start align-items-center mb-2">Sources of Other Income <small className="d-inline-block ms-1">(Optional)</small></label>
                        <textarea aria-label="Sources of Other Income" rows={5} aria-describedby="otherIncomeDetails"
                            placeholder="If you do have other income per month, enter details and any information that may be relevant to your application." id="otherIncomeDetails" defaultValue={formData.otherIncomeDetails}
                            {...register("otherIncomeDetails")} className="form-control"
                        ></textarea>
                    </div>
                </div>
            }
        </>
    );
}

export default IncomeDetails;