import React from 'react';
import EmailAddress from '../common/email-address';
import MobileNumber from '../common/mobile-number';
import LandlineNumber from '../common/landline-number';
import AddressInput from '../address/address-input';
import Notes from '../common/notes';
import SimpleCrypto from "simple-crypto-js";

interface IProps {
    formData: any;
    includeMobileNumber: boolean;
}

//TODO: Single error message for required + validate rules?
const BusinessContactDetails: React.FC<IProps> = ({ formData, includeMobileNumber }) => {
    //TODO: accept as component params
    const queryParams = new URLSearchParams(window.location.search);
    let emailDefaultValue = formData.emailAddress;
    let isPrepopulated = false;

    // Check for prepopulated email address in the URL and decrypt
    if (queryParams.get("email") !== null) {
        let simpleCrypto = new SimpleCrypto('pR0p3m41l!');
        let decryptedEmail = simpleCrypto.decrypt(queryParams.get("email")) || null;

        if (decryptedEmail) {
            isPrepopulated = true;
            emailDefaultValue = simpleCrypto.decrypt(queryParams.get("email"));
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-3 mb-md-4 mb-lg-5">Contact Details</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-8 mb-3">
                    <EmailAddress defaultValue={emailDefaultValue} readOnly={isPrepopulated} />
                </div>
                <div className="col-24 col-md-8 mb-3">
                    <LandlineNumber label="Business Landline" defaultValue={formData.landline} index={0} collectionName={null} />
                </div>
                {includeMobileNumber && 
                    <div className="col-24 col-md-8 mb-3">
                        <MobileNumber defaultValue={formData.mobileNumber} />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-24">
                    <h3 className="w-100 d-block h4 mt-3 mt-md-4 mt-lg-5 mb-3">Trading Address</h3>
                </div>
            </div>
            <AddressInput formData={formData} index={0} collectionName={null} />
            <div className="row">
                <div className="col-24">
                    <Notes defaultValue={formData.notes} />
                </div>
            </div>
        </>
    );
}

export default BusinessContactDetails;